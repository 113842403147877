export const regions = [
  {
    id: 0,
    name_en: "Ivanovo Region",
    name_ru: "Ивановская область",
    p: { x: "64.3%", y: "25.86%" },
  },
  {
    id: 1,
    name_en: "Tula Region",
    name_ru: "Тульская область",
    p: { x: "37.92%", y: "58.71%" },
  },
  {
    id: 2,
    name_en: "Belgorod Region",
    name_ru: "Белгородская область",
    p: { x: "43.22%", y: "87.84%" },
  },
  {
    id: 3,
    name_en: "Bryansk Region",
    name_ru: "Брянская область",
    p: { x: "17.55%", y: "66.45%" },
  },
  {
    id: 4,
    name_en: "Vladimir Region",
    name_ru: "Владимирская область",
    p: { x: "60.77% ", y: "36.25%" },
  },
  {
    id: 5,
    name_en: "Voronezh Region",
    name_ru: "Воронежская область",
    p: { x: "57.54%", y: "81.87%" },
  },
  {
    id: 6,
    name_en: "Kaluga Region",
    name_ru: "Калужская область",
    p: { x: "25.51%", y: "50.67%" },
  },
  {
    id: 7,
    name_en: "Kostroma Region",
    name_ru: "Костромская область",
    p: { x: "72.11%", y: "10.23%" },
  },
  {
    id: 8,
    name_en: "Kursk Region",
    name_ru: "Курская область",
    p: { x: "33.18%", y: "80.46%" },
  },
  {
    id: 9,
    name_en: "Lipetsk Region",
    name_ru: "Липецкая область",
    p: { x: "46.8%", y: "71.8%" },
  },
  {
    id: 10,
    name_en: "Moscow",
    name_ru: "Москва",
    p: { x: "38.28%", y: "39.97%" },
  },
  {
    id: 11,
    name_en: "Moscow Region",
    name_ru: "Московская область",
    p: { x: "39.69%", y: "35.49%" },
  },
  {
    id: 12,
    name_en: "Oryol Region",
    name_ru: "Орловская область",
    p: { x: "35.63%", y: "65.38%" },
  },
  {
    id: 13,
    name_en: "Ryazan Region",
    name_ru: "Рязанская область",
    p: { x: "58.5%", y: "54%" },
  },
  {
    id: 14,
    name_en: "Smolensk Region",
    name_ru: "Смоленская область",
    p: { x: "16.7%", y: "44.72%" },
  },
  {
    id: 15,
    name_en: "Tambov Region",
    name_ru: "Тамбовская область",
    p: { x: "64.45%", y: "67.18%" },
  },
  {
    id: 16,
    name_en: "Tver Region",
    name_ru: "Тверская область",
    p: { x: "24.46%", y: "30.25%" },
  },
  {
    id: 17,
    name_en: "Yaroslavl Region",
    name_ru: "Ярославская область",
    p: { x: "50.49%", y: "19.92%" },
  },
];
