import React, { useState, useEffect } from "react";
import reactDom from "react-dom";
import { regions } from "../../data/regions";
import Curtain from "../Curtain/Curtain";
import "./Regions.css";
import { rand } from "../../data/func";

const Regions = React.memo(({ handler, close, active, lang }) => {
  const [loads, setLoads] = useState([]);
  const [wd, setWd] = useState(10);

  useEffect(() => {
    const id = setInterval(() => {
      if (wd < 90) {
        setWd((a) => a + rand(1, 3));
      } else {
        clearInterval(id);
      }
    }, 1000);
    return () => {
      clearInterval(id);
    };
  }, [wd]);
  return (
    <>
      {regions.map((el) => (
        <React.Fragment key={el.id}>
          <button
            disabled={loads.indexOf(el.id) < 0}
            onClick={() => {
              handler(el.id);
            }}
            className="regions_label"
            style={{
              top: `${el.p.y}`,
              left: `${el.p.x}`,
            }}
          >
            <div className="load" style={{ width: `${wd}%` }}></div>
            {lang === "ru" ? el.name_ru : el.name_en}
          </button>
          {reactDom.createPortal(
            <Curtain
              lang={lang}
              region={el}
              active={active}
              close={close}
              ready={() => {
                setLoads([...loads, el.id]);
              }}
            />,
            document.getElementById("wrapper")
          )}
        </React.Fragment>
      ))}
    </>
  );
});

export default Regions;
