import React from "react";

const Info = React.memo(({ handle, active, close, lang }) => {
  return (
    <>
      <img
        src={`/img/title_${lang}.svg`}
        alt=""
        className="app_title"
        onClick={handle}
      />
      <div className={`info ${active ? "info_active" : ""}`}>
        <span className="close" onClick={close}>
          <img src="/img/close.svg" alt="" />
        </span>
        <img src={`/img/info_${lang}.svg`} alt="" />
        <div className="close_arrow">
          <img
            src={`/img/arrow_${lang}.svg`}
            alt=""
            onClick={close}
            style={lang === "en" ? { width: "10.5%" } : {}}
          />
        </div>
      </div>
    </>
  );
});
export default Info;
