import React, { useState, useEffect } from "react";
import App from "../App/App";
const user = { login: "admin", password: "admin" };

function Auth() {
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [chek, setChek] = useState(true);
  const [lang, setLang] = useState("ru");

  useEffect(() => {
    // if (sessionStorage.getItem("user") === "1") {
    //   setChek(true);
    // }
  }, []);

  return (
    <>
      {chek ? (
        <App
          logOut={() => {
            setChek(false);
            sessionStorage.removeItem("user");
          }}
          lang={lang}
          setLang={setLang}
        />
      ) : (
        <form
          className="login_form"
          onSubmit={(e) => {
            e.preventDefault();
            if (password === user.password && login === user.login) {
              setChek(true);
              sessionStorage.setItem("user", "1");
            }
          }}
        >
          {/* <select
            name="language"
            onChange={(e) => {
              setLang(e.target.value);
            }}
          >
            <option value="ru">Русский</option>
            <option value="en">English</option>
          </select> */}
          <input
            type="text"
            value={login}
            onChange={(e) => setLogin(e.target.value)}
            placeholder="login"
          />

          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            placeholder="password"
          />

          <input type="submit" value={lang === "ru" ? "Войти" : "Login"} />
        </form>
      )}
    </>
  );
}

export default Auth;
