import React from "react";

const Spin = () => {
  return (
    <div className="spin">
      <img src="/img/spin.gif" alt="" />
    </div>
  );
};
export default Spin;
