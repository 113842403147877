import React, { useEffect, useRef } from "react";
import "./Curtain.css";

const Curtain = React.memo(({ close, active, region, ready, lang }) => {
  const w = useRef();
  useEffect(() => {
    if (active) {
      w.current.scroll(0, 0);
    }
  }, [active]);

  return (
    <div
      ref={w}
      className={`curtain ${active === region.id ? "curtain_active" : ""}`}
      onClick={(e) => {
        e.stopPropagation();
      }}
      onMouseMove={(e) => {
        e.stopPropagation();
      }}
      onMouseDown={(e) => {
        e.stopPropagation();
      }}
      onMouseUp={(e) => {
        e.stopPropagation();
      }}
      onScroll={(e) => {
        e.stopPropagation();
      }}
      onTouchMove={(e) => {
        e.stopPropagation();
      }}
      onTouchStart={(e) => {
        e.stopPropagation();
      }}
      onTouchEnd={(e) => {
        e.stopPropagation();
      }}
    >
      <div className="curtain_inner">
        <span className="close" onClick={close}>
          <img src="/img/close.svg" alt="" />
        </span>
        <img
          src={region ? `/img/content_${lang}/${region.id}.svg` : null}
          alt=""
          onLoad={ready}
        />
      </div>
      <div className="close_arrow">
        <img
          src={`/img/arrow_${lang}.svg`}
          alt=""
          onClick={close}
          style={lang === "en" ? { width: "6.14%" } : {}}
        />
      </div>
    </div>
  );
});

export default Curtain;
