import React from "react";

const Shtor = React.memo(({ close, active }) => {
  return (
    <div
      onClick={close}
      className="sht"
      style={active ? { display: "block" } : { display: "none" }}
    ></div>
  );
});

export default Shtor;
