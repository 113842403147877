import React from "react";

const MiniMap = ({ handleMiniMap, miniMap }) => {
  return (
    <div className="mini_map_wrap">
      <div
        className="mini_map"
        onClick={handleMiniMap}
        style={{
          width: `${miniMap.mapW}px`,
          height: `${miniMap.mapH}px`,
        }}
      >
        <span
          style={{
            width: `${miniMap.screenW}px`,
            height: `${miniMap.screenH}px`,
            top: `${miniMap.screenTop}px`,
            left: `${miniMap.screenLeft}px`,
          }}
        ></span>
      </div>
    </div>
  );
};
export default MiniMap;
